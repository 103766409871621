<template>
  <div class="safe-claim">
    <h2 class="safe-claim-title">
      Safe Claim
    </h2>
    <div class="safe-claim-description">
      Here you can connect your main wallet to a burner wallet
    </div>
    <div class="safe-claim-row">
      <div class="safe-claim-content">
        <div class="safe-claim-source safe-claim-step">
          <div class="safe-claim-step-title">
            Source Wallet
          </div>
          <div class="safe-claim-step-description">
            Enter Source Wallet with your tokens
          </div>
          <input
            class="app-input safe-claim-step-input"
            :value="sourceAddress"
            type="text"
            @input="updateSource"
          >
          <div
            v-if="sourceAddressInvalid"
            class="safe-claim-step-input-error"
          >
            Wallet Address is invalid
          </div>
          <!-- <h3>{{ statusText }}</h3> -->
        </div>
        <div
          v-if="status === `request` || status === `confirmed`"
          class="safe-claim-controller safe-claim-step"
        >
          <div class="safe-claim-step-title">
            Controller Wallet
          </div>
          <div
            class="safe-claim-step-description"
          >
            Enter Controller Wallet that will be used as a proxy for your main wallet
          </div>
          <input
            class="app-input safe-claim-step-input"
            :value="controllerAddress"
            type="text"
            @input="updateController"
          >
          <button
            v-if="status !== 'confirmed' && requestInfo.controller !== controllerAddress"
            class="safe-claim-button"
            @click="handleRequest"
          >
            Attach Controller Wallet
          </button>
        </div>

        <div
          v-if="status === `confirming`"
          class="safe-claim-transfer"
        >
          <div
            v-if="requestInfo"
            class="safe-claim-transfer-info"
          >
            <div
              class="safe-claim-step-title safe-claim-transfer-info-title"
            >
              Send this amount of ETH from the Source wallet to Controller wallet
            </div>
            <div
              v-if="!requestConfirmed"
              class="safe-claim-transfer-detail"
            >
              <span>Amount Requested:</span>
              <span>
                {{ getEthAmount }} ETH ({{ ethAmountUSD }}$)
                <copyIcon
                  width="20"
                  @click="copyAmountToClipboard"
                />
                <span
                  v-if="showCopied"
                  class="safe-claim-transfer-copied"
                >Copied!</span>
              </span>
            </div>
            <div class="safe-claim-transfer-detail">
              <span>Source:</span>
              <span>{{ requestInfo.source }}</span>
            </div>
            <div class="safe-claim-transfer-detail">
              <span>Controller:</span>
              <span>{{ requestInfo.pendingController }}</span>
            </div>
            <button
              class="safe-claim-transfer-change-controller"
              @click.prevent="changeController"
            >
              Change Controller
            </button>
          </div>
          <div
            v-if="status === `confirming`"
            class="safe-claim-transaction"
          >
            <div class="safe-claim-step-title">
              Transaction Hash
            </div>
            <div class="safe-claim-step-description">
              Enter Transaction hash
            </div>
            <input
              class="app-input safe-claim-step-input"
              :value="txHash"
              type="text"
              @input="updateTxHash"
            >
            <div
              v-if="txHashError"
              class="safe-claim-step-input-error"
            >
              {{ txHashError }}
            </div>
            <div
              v-if="txHash && !isValidHash"
              class="safe-claim-step-error"
            >
              Please use a valid tx hash
            </div>
            <button
              class="safe-claim-button"
              @click="confirmRequest"
            >
              Confirm
            </button>
          </div>
        </div>
        <div
          v-if="status == 'confirmed'"
          class="safe-claim-confirmed"
        >
          <div class="safe-claim-confirmed-banner">
            Confirmed
          </div>
          <div>
            <a
              class="safe-claim-confirmed-button"
              target="_blank"
              :href="'/?source=' + sourceAddress"
              @click.prevent="goToMyCollection"
            >Visit Source Wallet Collection As Controller Wallet</a>
          </div>
        </div>
      </div>
      <div class="safe-claim-step-items">
        <div class="safe-claim-step-items-title">
          Instructions:
        </div>
        <div class="safe-claim-step-item">
          <div class="safe-claim-step-item-num">
            1
          </div>Enter Your Main (Source) Wallet
        </div>
        <div class="safe-claim-step-item">
          <div class="safe-claim-step-item-num">
            2
          </div>Enter your Controller (Proxy) Wallet
        </div>
        <div class="safe-claim-step-item">
          <div class="safe-claim-step-item-num">
            3
          </div>Send Transaction with small amount to prove you are an owner of the wallet
        </div>
        <div class="safe-claim-step-item">
          <div class="safe-claim-step-item-num">
            4
          </div>Enter Transaction Hash
        </div>
      </div>
    </div>
    <div class="safe-claim-faq">
      <h2>Safe Claim FAQ</h2>
      <div class="safe-claim-faq-item">
        <div class="safe-claim-faq-item-title">
          1. What is Safe Claim?
        </div>

        <div
          class="safe-claim-faq-item-text"
        >
          Safe Claim allows you to harvest METH from your NFTs to METH bank and claim the harvested METH from METH bank to your wallet without ever having to connect your wallet which holds all your harvestable NFTs!
        </div>
      </div>
      <div class="safe-claim-faq-item">
        <div class="safe-claim-faq-item-title">
          2. How does it work?
        </div>
        <div class="safe-claim-faq-item-text">
          For harvesting and claiming METH, you can create a new Ethereum wallet and use it as a proxy to your main wallet which contains all your harvestable NFTs.
          Please watch the video below to know how to connect your Proxy wallet (Controller Wallet) to your Main wallet (Source wallet) and then use your proxy wallet to harvest and claim METH
        </div>
      </div>
      <div>
        <video
          class="safe-claim-faq-video"
          controls
        >
          <source src="../assets/video/safe_claim_faq.mp4">
        </video>
      </div>
      <div class="safe-claim-faq-item">
        <div
          class="safe-claim-faq-item-title"
        >
          3. When I Claim METH, which wallet will get the METH that I claim?
        </div>
        <div class="safe-claim-faq-item-text">
          Controller wallet will get the METH you claim.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import copyIcon from "../assets/icons/copy.svg?inline";
import { Config } from "../config";
import a from "axios";
import { connectWallet } from "@/web3"
import Web3 from "web3";

import { useWeb3Store } from "@/store/web3";
const axios = a.create()
const { apiUrl } = Config;

export default {
  components: { copyIcon },
  data() {
    return {
      sourceAddress: "",
      controllerAddress: "",
      txHash: "",
      isValidHash: false,
      status: "",
      controllerInfo: null,
      requestInfo: null,
      requestConfirmed: false,
      showCopied: false,
      txHashError: '',
      ethPrice: 0
    };
  },
  
  computed: {
    sourceAddressInvalid() {
      if (!this.sourceAddress) return false
      return !Web3.utils.isAddress(this.sourceAddress);
    },
    ethAmountUSD() {
      return (this.ethPrice * this.getEthAmount).toFixed(5)
    },
    getEthAmount: function () {
      const amount = this.requestInfo.amountRequested;
      console.log(amount.toString(), amount, "amount");
      return Web3.utils.fromWei(amount.toString());
    },
    statusText: function () {
      switch (this.status) {
        case "confirming":
          return "has a pending request";
        case "info":
          return "has a confirmed controller";
        case "request":
          return "is requesting a controller";
        case "confirmed":
          return "is requesting a controller";

        default:
          return "Add a valid source address";
      }
    },
    statusTextController: function () {
      if (this.status === "confirmed") {
        return "is a confirmed controller";
      } else if (this.status === "request" && this.controllerAddress) {
        return "is a valid controller";
      }

      return "Add a valid controller address";
    },
  },
  watch: {
    showCopied() {

    }
  },
  created() {
    this.getEthPrice()
  },
  methods: {
    async goToMyCollection() {
      await connectWallet()
      const web3Store = useWeb3Store()
      if (web3Store.canBeController) {
        web3Store.safeClaim.isController = true
      }
      this.$router.push("/collection");
    },
    async getEthPrice() {
      const response = await axios.get('https://min-api.cryptocompare.com/data/price', {
        params: {
          fsym: 'ETH',
          tsyms: 'USD'
        }
      })
      this.ethPrice = response.data.USD
    },
    changeController() {
      this.status = 'request'
    },
    async updateSource(e) {
      this.requestInfo = null;
      this.requestConfirmed = false;
      const isAddress = Web3.utils.isAddress(e.target.value);
      this.sourceAddress = e.target.value;

      if (isAddress) {
        try {
          const {
            data: { info },
          } = await axios.get(
            `${apiUrl}/safeclaim/info?type=source&address=${e.target.value}`
          );

          this.controllerInfo = info;

          if (!info.controller) {
            this.status = "request";
            this.requestInfo = info;
          } else if (!info.confirmed) {
            this.status = "confirming";
            this.requestInfo = info;
            console.log(this.requestInfo, "this.requestInfo");
          } else if (info.confirmed) {
            this.status = "confirmed";
            this.controllerAddress = info.controller
            this.requestInfo = info;

          } else {
            this.status = "info";
            this.requestInfo = info;
          }
          console.log(info);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.status = "";
      }

    },
    async updateController(e) {
      const isAddress = Web3.utils.isAddress(e.target.value);
      this.controllerAddress = e.target.value;

      if (!isAddress) return
      try {
        const {
          data: { info },
        } = await axios.get(
          `${apiUrl}/safeclaim/info?type=controller&address=${e.target.value}`
        );

        this.controllerInfo = info;

        if (info.confirmed) {
          this.status = "confirmed";
        } else {
          this.status = "request";
        }
        console.log(info);
      } catch (error) {
        console.log(error);
      }


    },
    async updateTxHash(e) {
      this.isValidHash = /^0x([A-Fa-f0-9]{64})$/.test(e.target.value);

      this.txHash = e.target.value;
    },

    async handleRequest() {
      try {
        const { data } = await axios.post(
          `${apiUrl}/safeclaim/request`,
          {
            source: this.sourceAddress,
            controller: this.controllerAddress,
          }
        );

        if (data.success) {
          this.requestInfo = {
            ...data.info.Item,
            amountRequested: data.info.amountRequested,
          };
          this.status = "confirming";
          console.log(this.requestInfo);
        }
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          console.log(data);
        } else {
          console.log(error);
        }
      }
    },

    async confirmRequest() {
      this.txHashError = ''
      try {
        const { data } = await axios.post(
          `${apiUrl}/safeclaim/confirm`,
          {
            source: this.sourceAddress,
            txHash: this.txHash,
          }
        );

        if (data.success) {
          this.requestConfirmed = data.info.Attributes.confirmed;
          console.log(data.info.Attributes.confirmed);
          console.log(data);
          this.status = "confirmed";

        }
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          console.log(data);
          this.txHashError = data.error
        } else {
          console.log(error);
        }
      }
    },
    copyAmountToClipboard() {
      const amount = this.requestInfo.amountRequested;
      navigator.clipboard.writeText(Web3.utils.fromWei(amount.toString()));
      this.showCopied = true
      clearTimeout(this.copiedTimeout)
      this.copiedTimeout = setTimeout(() => {
        this.showCopied = false
        clearTimeout(this.copiedTimeout)
      }, 1000);
    },
  }
};
</script>
